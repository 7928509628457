import * as Sentry from '@sentry/gatsby';

const disallowedFilenames = [
  'https://www.googletagmanager.com/gtm.js',
  'https://cdn.cookielaw.org/scripttemplates',
  'https://geolocation.onetrust.com/',
  'https://cdn.heapanalytics.com/js',
  'https://bite.australiarevival.com',
  'https://spider.australiarevival.com/',
  'https://tracking.g2crowd.com/',
  
  'https://static.klaviyo.com/onsite',
  '/onsite/js/',

  'https://js.qualified.com',
  'https://assets.qualified.com',
  'https://player.vimeo.com',
  'https://f.vimeocdn.com',
  'https://cdn.pdst.fm',
  'https://www.redditstatic.com/',
  
  'https://ct.capterra.com/',
  'https://client-registry.mutinycdn.com/',
  'https://extend.vimeocdn.com/',
  'https://d41.co/',

  'https://tracking.intentsify.io',
  'https://js.adsrvr.org/',
  'https://insight.adsrvr.org/',
];

Sentry.init({
  beforeSend(event) {
    if (event.exception?.values?.[0]?.stacktrace?.frames?.some((f) => disallowedFilenames.some((df) => f.filename?.startsWith(df)))) {
      return null;
    }

    return event;
  },
  dsn: __SENTRY_DSN__,
  environment: process.env.NODE_ENV,
  sampleRate: __SENTRY_SAMPLE_RATE__,
  tracesSampleRate: __SENTRY_TRACES_SAMPLE_RATE__,
});
