import { createInstance } from 'i18next';

import deDE from '@klaviyo-mkt/translations/de-DE.json';
import enAU from '@klaviyo-mkt/translations/en-AU.json';
import enGB from '@klaviyo-mkt/translations/en-GB.json';
import enUS from '@klaviyo-mkt/translations/en-US.json';
import esES from '@klaviyo-mkt/translations/es-ES.json';
import frFR from '@klaviyo-mkt/translations/fr-FR.json';
import itIT from '@klaviyo-mkt/translations/it-IT.json';
import koKR from '@klaviyo-mkt/translations/ko-KR.json';
import ptBR from '@klaviyo-mkt/translations/pt-BR.json';
// TRANSLATION_IMPORTS

const initI18n = (langCode: string) => {
  const i18n = createInstance();

  i18n.init({
    resources: {
      'de-DE': {
        translation: deDE,
      },
      'en-AU': {
        translation: enAU,
      },
      'en-GB': {
        translation: enGB,
      },
      'en-US': {
        translation: enUS,
      },
      'es-ES': {
        translation: esES,
      },
      'fr-FR': {
        translation: frFR,
      },
      'it-IT': {
        translation: itIT,
      },
      'ko-KR': {
        translation: koKR,
      },
      'pt-BR': {
        translation: ptBR,
      },
      // TRANSLATION_RESOURCES
    },
    debug: process.env.NODE_ENV === 'development',
    lng: langCode,
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

  return i18n;

};

export default initI18n;
